const ExchangeArtIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M22 12C22 17.5228 17.5228 22 12 22V22C6.47715 22 2 17.5228 2 12V12C2 6.47715 6.47715 2 12 2V2C17.5228 2 22 6.47715 22 12V12ZM13.512 16.8252C13.5123 16.8251 13.5127 16.825 13.5131 16.825C14.3354 16.825 15.1577 16.826 15.98 16.825C16.514 16.824 16.809 16.526 16.81 15.9885C16.8115 15.1825 16.81 14.3765 16.81 13.5705C16.81 12.7395 16.814 11.909 16.808 11.078C16.804 10.562 16.533 10.301 16.02 10.298C15.887 10.2975 15.754 10.298 15.621 10.298C14.9935 10.2985 14.7655 10.524 14.7625 11.1505C14.7575 12.239 14.753 13.327 14.749 14.4155C14.7475 14.749 14.748 14.748 14.4075 14.749C13.2945 14.753 12.181 14.7555 11.068 14.7645C10.5225 14.7685 10.2785 15.0195 10.277 15.5595C10.277 15.709 10.277 15.8585 10.277 16.008C10.2775 16.583 10.5145 16.8225 11.0935 16.824C11.8993 16.826 12.7051 16.8245 13.5109 16.8255C13.5113 16.8255 13.5117 16.8254 13.512 16.8252V16.8252ZM7.2395 10.49C7.2395 11.3125 7.2355 12.135 7.241 12.958C7.2445 13.493 7.5085 13.7485 8.0405 13.75C8.1735 13.75 8.3065 13.75 8.4395 13.75C9.0505 13.751 9.2845 13.52 9.2875 12.9145C9.2925 11.793 9.3035 10.671 9.299 9.5495C9.298 9.35 9.354 9.297 9.5515 9.2985C10.4985 9.305 11.4455 9.2965 12.393 9.2925C12.609 9.2915 12.8255 9.2935 13.041 9.2845C13.53 9.264 13.7675 9.016 13.7705 8.5305C13.7715 8.3725 13.7705 8.215 13.7705 8.057C13.77 7.4705 13.5545 7.2245 12.9725 7.221C11.3355 7.2105 9.6985 7.211 8.062 7.2215C7.5205 7.225 7.242 7.533 7.241 8.072C7.2395 8.878 7.24 9.684 7.2395 10.49Z"
			fill="currentColor"
		/>
		<title>ExchangeArt</title>
	</svg>
)

export default ExchangeArtIcon
