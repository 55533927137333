const SolanaFMIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<mask
			id="mask0_568_34"
			// mask-type="luminance"
			style={{ maskType: 'luminance' }}
			maskUnits="userSpaceOnUse"
			x="5"
			y="2"
			width="14"
			height="20"
		>
			<path d="M18.7801 2H5V22H18.7801V2Z" fill="white" />
		</mask>
		<g mask="url(#mask0_568_34)">
			<path
				d="M5 11.9411C5.01888 9.30039 6.0794 6.7729 7.9528 4.91172C9.82621 3.04942 12.3592 2.00333 15 2H15.7318V3.58912H15C12.7801 3.59134 10.6513 4.47085 9.07662 6.03443C7.50194 7.598 6.608 9.72127 6.58912 11.9411H5Z"
				fill="currentColor"
			/>
			<path
				d="M8.04831 22V20.4109H8.77568C10.9956 20.4087 13.1244 19.5292 14.6991 17.9656C16.2737 16.4009 17.1677 14.2787 17.1866 12.0589H18.7757C18.7568 14.6996 17.6963 17.2271 15.8229 19.0883C13.9495 20.9506 11.4164 21.9967 8.77568 22H8.04831Z"
				fill="currentColor"
			/>
			<path
				d="M8.04831 18.9517V17.3626H8.77568C10.1882 17.3626 11.543 16.8051 12.548 15.8123C13.553 14.8196 14.126 13.4714 14.1427 12.0589H15.7318C15.7141 13.8923 14.9734 15.6447 13.6707 16.9362C12.3681 18.2266 10.6091 18.9517 8.77568 18.9517H8.04831Z"
				fill="currentColor"
			/>
			<path
				d="M8.04831 11.9411C8.06608 10.1088 8.80567 8.35647 10.1072 7.06608C11.4087 5.77568 13.1666 5.05053 15 5.04831H15.7318V6.63743H15C13.5886 6.63854 12.2338 7.196 11.231 8.18879C10.2271 9.18157 9.65519 10.5297 9.63743 11.9411H8.04831Z"
				fill="currentColor"
			/>
			<path
				d="M8.04831 15.9045V14.3143H8.77568C9.37979 14.3143 9.96058 14.0789 10.3937 13.658C10.8268 13.2371 11.0777 12.663 11.0933 12.0589V12C11.0944 10.965 11.5064 9.97224 12.2393 9.24043C12.9722 8.50861 13.965 8.09773 15 8.09662H15.7318V9.68574H15C14.397 9.68574 13.8173 9.92227 13.3853 10.3443C12.9534 10.7651 12.7024 11.3382 12.6868 11.9411V12C12.6857 13.0361 12.2726 14.03 11.5397 14.7618C10.8068 15.4936 9.81177 15.9045 8.77568 15.9045H8.04831Z"
				fill="currentColor"
			/>
		</g>
		<title>SolanaFM</title>
	</svg>
)

export default SolanaFMIcon
