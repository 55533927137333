const HighlightIcon = ({ ...props }) => (
    <svg
        {...props}
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path fillRule="evenodd" clipRule="evenodd" d="M22.0567 12.0204C22.0699 6.46939 17.6053 2 12.0619 2C6.56963 2 2.10025 6.40403 2.00175 11.7249C1.89851 17.3327 6.37926 21.8551 11.7664 21.9962C17.2388 22.1402 22.0425 17.7664 22.0567 12.0204ZM7 7.99987V15.9884C7.02953 15.9901 7.05432 15.9919 7.07612 15.9935C7.11291 15.9961 7.14115 15.9982 7.16918 15.9982C7.51014 15.9982 7.85112 15.9983 8.19211 15.9985C10.2374 15.9991 12.283 15.9997 14.3282 15.9953C14.5376 15.9947 14.7503 15.965 14.9553 15.9187C16.9304 15.4695 18.1951 13.6098 17.9752 11.4872C17.7719 9.52409 16.0966 8.00102 14.1348 7.99987C12.6175 7.99911 11.0999 7.99937 9.58242 7.99962C8.82371 7.99975 8.06502 7.99987 7.3064 7.99987H7ZM10.3423 11.9976C10.3417 13.2418 10.8234 14.2791 11.7751 15.1306H7.80622V8.87714H11.7696C10.8333 9.72069 10.3423 10.7528 10.3423 11.9976ZM11.1468 11.9919C11.1496 10.4328 12.4953 8.83714 13.749 8.92743V15.0763C12.4887 15.1666 11.1441 13.5658 11.1468 11.9919ZM14.5999 15.0963V8.92629C15.8034 8.84571 17.1029 10.3288 17.1949 11.8781C17.2869 13.4326 16.0238 15.014 14.5999 15.0963Z" fill="currentColor" />
    </svg>
)

export default HighlightIcon
