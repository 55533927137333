const TZKTIcon = ({ ...props }) => (
	<svg
		{...props}
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.392 11.5472V10.3627L17.4708 5.24873V5.18178H13.5259V3.63678H19.7522V4.90883L15.8691 9.93524V10.0022H19.8861V11.5472H13.392Z"
			fill="currentColor"
		/>
		<path
			d="M4.49567 2.60165V1H12.9108V2.60165H9.65083V11.5472H7.75562V2.60165H4.49567Z"
			fill="currentColor"
		/>
		<path
			d="M12.5139 14.0544V12.4528H20.929V14.0544H17.669V23H15.7738V14.0544H12.5139Z"
			fill="currentColor"
		/>
		<path
			d="M3.45282 23V12.4528H5.36347V17.2989H5.49222L9.60708 12.4528H11.94L7.86123 17.1856L11.9761 23H9.67918L6.53253 18.4783L5.36347 19.8585V23H3.45282Z"
			fill="currentColor"
		/>
	</svg>
)

export default TZKTIcon
